<template>
  <div class="iconArrCss">
    <div class="svgIconContentCss">
      <div class="svgIconItemCss" v-for="(item, index) in iconArr" :key="index">
        <svg-icon :icon-class="item.iconClass" style="width:2rem;height:2rem" color="#666666" />
        <div class="titleCss">{{item.iconClass}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'iconArr',
  data() {
    return {
      iconArr: []
    }
  },
  mounted() {
    const files = require.context('@/assets/icons/svg', false, /.svg$/).keys()
    files.forEach(fileUrl => {
      this.iconArr.push({
        iconClass: fileUrl.slice(2, fileUrl.length - 4)
      })
    })
  }
}
</script>

<style lang="stylus" scoped>
.iconArrCss {
  width 99.8rem
  padding 2rem 0
  background-color #333439
  color white
  .svgIconContentCss {
    margin-left 10%
    width 80%
    .svgIconItemCss {
      width 6rem
      height 6rem
      float left
      text-align center
    }
  }
  .svgIconContentCss:after { // 使 svgIconContentCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>
